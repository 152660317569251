import { useState } from "react";
import styles from "./LicensePlate.module.css";

interface LicensePlateProps {
  onChange: (licensePlate: string) => void
}

export const LicensePlate = ({ onChange }: LicensePlateProps) => {
  const [licensePlate, setLicensePlate] = useState("");
  return (
    <div>
      <input
        className={styles.licensePlate}
        type="text"
        id="licensePlate"
        name="licensePlate"
        value={licensePlate}
        pattern="^[A-Za-z0-9 -]*$"
        onChange={e => { setLicensePlate(e.target.value.toUpperCase()); onChange(e.target.value)}}
        placeholder="AB CD 123E"
        autoFocus
      />
      <h3 className={styles.subtitle}>Bei Elektro-Autos auch das E angeben.</h3>
      <p className={styles.info}>Du kannst das Kennzeichen auch ohne Bindestrich oder Leerzeigen eingeben.</p>
    </div>
  );
}
