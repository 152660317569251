import { loadStripe } from "@stripe/stripe-js";
import { CheckoutForm } from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { Station } from "../../../services/uuno-api/stations";
import { Product } from "../../../services/uuno-api/products";

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

interface StripeWalletButtonProps {
  station: Station;
  product: Product;
  licensePlate: string | undefined;
}

export const StripeWalletButton = ({ station, product, licensePlate } : StripeWalletButtonProps) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm station={station} product={product} licensePlate={licensePlate} />
    </Elements>
  );
};
