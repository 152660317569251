import { useLocation, useNavigate } from "react-router-dom";
import styles from "./WashCode.module.css";
import { Button } from "../../components/Button";

export interface WashCodeDetails {
  washCode: string;
  expirationDate?: string;
  email: string;
}

export const WashCode = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const washCodeDetails = state as WashCodeDetails;

  const goToInstructions = () => {
    navigate("/wash-code/instructions", { state: washCodeDetails });
  };

  const goToHelp = () => {
    navigate("/help");
  };

  return (
    <div>
      {washCodeDetails && washCodeDetails.washCode && (
        <>
          <div className={styles.washCodeWrapper}>
            <h1 className={styles.title}>Danke für deine Buchung</h1>
            <h2 className={styles.washCodeTitle}>Waschcode:</h2>
            <div className={styles.washCode}>
              {[...washCodeDetails.washCode].map((digit, index) => (
                <span key={index} className={styles.washCodeDigit}>{digit}</span>
              ))}
            </div>
            <br />

            {washCodeDetails.expirationDate && (
              <h3 className={styles.expirationDate}>
                gültig bis {new Date(washCodeDetails.expirationDate).toLocaleDateString("de-DE")}
              </h3>
            )}
          </div>

          <div className={styles.notes}>
            Diesen Waschcode und die Rechnung haben wir an deine E-Mail Adresse gesendet.
          </div>

          <Button label="So geht´s" onClick={goToInstructions} />
          <button className={styles.hilfeButton} onClick={goToHelp}>
            Hilfe
          </button>
        </>
      )}
    </div>
  );
};
