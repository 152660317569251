import client from "./base";

export enum ProductType {
  WashCode = 0,
  JetWash = 1
}

export interface Product {
  id: number;
  name: string;
  description: string | null;
  price: number;
  netPrice: number;
  vatAmount: number;
  vat: number;
  finalized: boolean;
  duration: number;
  hasBrands: boolean;
  hasStations: boolean;
  dealSmallImageUrl: string;
  dealBigImageUrl: string;
  dealDescription: string;
  dealIsPurchasable: boolean;
  dealVisible: boolean;
  dealId: number;
  isPurchasable: boolean;
  isDisabled: boolean;
  productType?: ProductType;
  superOperatorId?: number;
  superOperatorOperationId?: number;
}

export const getProducts = async (stationId: number) => {
  const response = await client.get<Product[]>(`/car-wash-stations/${stationId}/products`);
  return response.data;
};
