import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./Deal.module.css";
import { HemPayPalButton } from "./HemPayPalButton";
import { ProductDetails}  from "./ProductDetails";
import { getStation, Station } from "../../services/uuno-api/stations";
import { getProducts, Product, ProductType } from "../../services/uuno-api/products";
import { WashCodeConditions } from './WashCodeConditions';
import { StripeWalletButton } from './StripeWalletButton';
import { LicensePlate }  from "./LicensePlate";
import { PayPalButton } from "./PayPalButton";

export const Deal = () => {
  const [searchParams] = useSearchParams();
  const [station, setStation] = useState<Station>();
  const [products, setProducts] = useState<Product[]>();
  const [licensePlate, setLicensePlate] = useState<string>();

  const fetchStation = async (stationId: number) => {
    const station = await getStation(stationId);
    console.log(station);
    setStation(station);
  };

  const fetchProducts = async (stationId: number) => {
    const products = await getProducts(stationId);
    setProducts(products);
  };

  const stationId = useMemo(() => {
    const stationId = searchParams.get("stationId");
    if (stationId) {
      return parseInt(stationId);
    }
  }, [searchParams]);

  useEffect(() => {
    if (stationId) {
      fetchStation(stationId);
      fetchProducts(stationId);
    }
  }, [stationId]);

  const dealProduct = useMemo(() => {
    return products?.find((p) => {
      return p.finalized && p.dealId && p.dealVisible;
    });
  }, [products]);

  return (
    <div>
      {station && dealProduct && (
        <>
          {!station.mphSiteId && dealProduct.dealSmallImageUrl && (
            <img className={styles.banner} src={dealProduct.dealSmallImageUrl} alt="Deal" />
          )}

          {!!station.mphSiteId && dealProduct.dealBigImageUrl && (
            <img className={styles.banner} src={dealProduct.dealBigImageUrl} alt="Deal" />
          )}

          <div className={styles.payment}>
            {/* <ProductDetails product={dealProduct} /> */}

            
            {/* HEM is disabled for now
            !!station.mphSiteId && (
              <>
                <WashCodeConditions station={station} />
                <HemPayPalButton stationId={station.id} productId={dealProduct.id} />
              </>
            )*/}

            {dealProduct.productType === ProductType.WashCode && (
              <>
                <WashCodeConditions station={station} />
                <PayPalButton station={station} product={dealProduct} licensePlate={licensePlate} shape={"pill"} />
              </>
            )}

            {!!station.superOperatorId && !!dealProduct.superOperatorId && !!dealProduct.superOperatorOperationId && (
              <>
                <h2 className={styles.title}>Schritt 1: Gib dein Kennzeichen ein</h2>
                <LicensePlate onChange={licensePlate => setLicensePlate(licensePlate)}/>
                <h2 className={styles.title}>Schritt 2: Wähle eine Bezahlmethode</h2>
                <StripeWalletButton station={station} product={dealProduct} licensePlate={licensePlate} />
                <br/>
                <PayPalButton station={station} product={dealProduct} licensePlate={licensePlate} shape={"rect"} />
              </>
            )}

            <p className={styles.agb}>
              Es gelten die <a href="https://uuno.app/agb.html">AGB</a> von Superoperator GmbH
            </p>
          </div>
        </>
      )}
    </div>
  );
};
