import { useCallback } from "react";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Product, ProductType } from "../../../services/uuno-api/products";
import { AutostartInstructionsDetails } from "../../AutostartInstructions";
import { useNavigate } from "react-router-dom";
import { Station } from "../../../services/uuno-api/stations";
import {
  capturePayment,
  CapturePaymentRequest,
  createOrder,
  CreateOrderRequest
} from "../../../services/web-api/paypal";
import { licensePlateIsValid } from "../../../util/license-plate";

export interface PayPalButtonProps {
  shape: "rect" | "pill";
  station: Station;
  product: Product;
  licensePlate?: string | undefined;
}

export const PayPalButton = ({ station, product, licensePlate, shape }: PayPalButtonProps) => {
  const navigate = useNavigate();

  const goToAutostartInstructions = useCallback(() => {
    navigate("/instructions", { state: { station, licensePlate } as AutostartInstructionsDetails });
  }, [navigate, station, licensePlate]);

  return (
    <PayPalScriptProvider
      options={{
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID!,
        locale: "de_DE",
        currency: "EUR",
        intent: "capture"
      }
    }>
      <PayPalButtons
        forceReRender={[licensePlate]}
        style={{
          shape: shape,
          color: "blue",
          layout: "vertical",
          label: "paypal"
        }}
        fundingSource={"paypal"}
        onClick={async (data, actions) => {
          if (product.productType === null && !licensePlateIsValid(licensePlate)) {
            return await actions.reject();
          }
        }}
        createOrder={async (data, actions) => {
          const request: CreateOrderRequest = {
            stationId: station.id,
            productId: product.id,
          };

          const result = await createOrder(request);
          return result.orderId;
        }}
        onApprove={async (data, actions) => {
          const request: CapturePaymentRequest = {
            orderId: data.orderID,
            licensePlate: licensePlate!
          };

          const response = await capturePayment(request);

          if (product.productType === ProductType.WashCode) {
            navigate("/wash-code", { state: response });
          } else {
            goToAutostartInstructions();
          }
        }}
      />
    </PayPalScriptProvider>
  );
};
