import styles from "./WashCodeConditions.module.css";
import { Station } from '../../../services/uuno-api/stations';

interface WashCodeConditionsProps {
  station: Station;
}

export const WashCodeConditions = ({ station }: WashCodeConditionsProps) => {
  return (
    <ul className={styles.conditions}>
      {/*
      <li>
        <b>Nur einlösbar an dieser Station:</b>
        <br />
        {station.name} {station.street}
        <br />
        {station.zip} {station.city}
      </li>
      */}
      <li>
        Waschcode zur Eingabe an der Waschanlage wird nach dem Kauf
        angezeigt und zusätzlich per E-Mail gesendet.
      </li>
    </ul>
  )
};
